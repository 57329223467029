<template>
  <el-dialog title="是否同意撤销重审" :visible.sync="showInput" append-to-body>
    <el-form>
      <el-form-item label="审核情况: ">{{ data.revokeRemark }}</el-form-item>
      <el-form-item label="原因">
        <el-input
          type="textarea"
          placeholder="若您拒绝撤销，请输入原因"
          v-model="desc"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handle_btn(1)">拒绝撤销</el-button>
      <el-button
        @click="handle_btn(3)"
        v-if="data.revokeNeedModify"
        type="primary"
        >同意撤销并修改</el-button
      >
      <el-button @click="handle_btn(2)" v-else type="primary"
        >同意撤销</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    value(v) {
      this.showInput = v;
    },
    showInput(v) {
      this.$emit("input", v);
    },
  },
  data() {
    return {
      showInput: false,
      desc: undefined,
    };
  },
  methods: {
    async handle_btn(num) {
      if (num === 1 && !this.desc) {
        return this.$message.error("拒绝撤销必须填写您的原因~");
      }
      const info = {
        tid: this.data.tid,
        agreeRevoke: num !== 1,
        rejectRevokeRemark: this.desc,
      };
      const { errcode } = await this.$api.reports(info);
      this.$message.success("提交成功");
      if (errcode === 0 && num === 3) {
        this.$router.push(`submit_tag?modifyTagId=${info.tid}`);
        return;
      }
      this.showInput = false;
      this.$emit("submit");
    },
  },
};
</script>