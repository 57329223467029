import axios from '@/http/api'
import moment from 'moment'

export function getBatchOrderList(params) {
    return axios({
        url: '/api/batch/orders',
        method: 'get',
        params: {
            ...params,
            batchNo: params.keyword,
            start: params.start ? moment(params.start).format('YYYY-MM-DD hh:mm:ss') : undefined,
            end: params.end ? moment(params.end).format('YYYY-MM-DD hh:mm:ss') : undefined,
        }
    })
}
export function getOrderBatchInfo(batchNo) {
    return axios({
        url: `/api/batch/orders/${batchNo}`,
        method: 'get',
    })
}

export function downOrderFile(batchNo) {
    return axios({
        url: `/api/packreports/${batchNo}`,
        method: 'get',
    })

}