<template>
  <div>
    <div v-for="value in list">
      <a class="text-blue" @click="clickBtn(value)">
        {{ value.title }}
      </a>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
    },
  },
  watch: {
    "item.status": {
      handler() {
        this.list = [
          {
            title: "立即付款",
            status: [1],
            click() {
              this.$router.push(`submit_tag?state=3&id=${this.item.tid}`);
            },
          },
          {
            title: "撤销订单",
            status: [1],
            click() {
              this.MtaH5.clickStat("food", { close: "true" });
              this.removeTag(this.item.tid);
            },
          },
          {
            title: "审核报告",
            status: [9],
            click() {
              this.MtaH5.clickStat("food", { look: "true" });
              window.open(this.item.report.url, "_blank");
            },
          },
          {
            title: "重新提交",
            status: [9, 10, 11],
            click() {
              this.MtaH5.clickStat("food", { reset: "true" });
              this.$router.push(`submit_tag?resetSubmitId=${this.item.tid}`);
            },
          },
          {
            title: "立即修改",
            status: [20],
            click() {
              this.$router.push(`submit_tag?modifyTagId=${this.item.tid}`);
            },
          },
          {
            title: "处理重审",
            status: [21],
            click() {
              this.$emit("submit");
            },
          },
        ].filter((i) => {
          return i.status && i.status.includes(this.item.status);
        });
      },
      immediate: true,
    },
  },
  data() {
    return {
      list: [],
    };
  },

  methods: {
    clickBtn(value) {
      value.click.apply(this);
    },
    async removeTag(tid) {
      await this.$confirm("此操作将撤销该订单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
      const { errcode } = await this.$api.remove_orders({ tid });
      if (errcode === 0) {
        this.$message.success("订单已撤销成功~");
        this.$emit("change");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.link {
  cursor: pointer;
}
</style>