<template>
  <div>
    <el-dialog
      title="申请出具CMA检验报告"
      append-to-body
      :visible.sync="payCmaDlgVisible"
      :close-on-click-modal="false"
    >
      <el-dialog
        :visible.sync="showPayQrcode"
        append-to-body
        v-on:opened="showQrcode"
        :close-on-click-modal="false"
        :before-close="cancelWxPay"
      >
        <div class="pop_code">
          <div class="pop_box_wrap">
            <div class="pop_box">
              <div class="title">
                <div class="name">
                  <h3><span class="iconfont iconwxzhifu"></span>微信支付</h3>
                </div>
              </div>
              <div class="code_text">
                应付金额：
                <span class="money">{{ formData.cma.payment | currency }}</span>
              </div>
              <div class="code_img">
                <div id="qrcode" class="dib" ref="qrcode"></div>
              </div>
              <div class="wx_pay">
                <img src="/static/img/wx.png" alt />
              </div>
            </div>
          </div>
        </div>
      </el-dialog>
      <div>
        <el-form
          ref="pay-form"
          :model="formData"
          label-width="auto"
          size="small"
        >
          <el-row>
            <el-col :span="12">
              <el-form-item label="订单号">{{ formData.tid }} </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="食品名称"
                >{{ formData.foodsName }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item
            label="委托单位名称"
            :rules="[
              {
                required: true,
                message: '必须输入委托单位的全称',
                trigger: 'blur',
              },
            ]"
            prop="cma.requesterName"
          >
            <el-input
              placeholder="请输入委托单位的全称"
              v-model="formData.cma.requesterName"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="委托单位地址"
            :rules="[
              {
                required: true,
                message: '必须输入委托单位的地址',
                trigger: 'blur',
              },
            ]"
            prop="cma.requesterAddr"
          >
            <el-input
              placeholder="请输入委托单位的地址"
              v-model="formData.cma.requesterAddr"
            ></el-input>
          </el-form-item>
          <el-row>
            <el-col :span="12">
              <el-form-item
                label="委托单位联系人"
                :rules="[
                  {
                    required: true,
                    message: '必须输入联系人姓名',
                    trigger: 'blur',
                  },
                ]"
                prop="cma.requesterContact"
              >
                <el-input
                  placeholder="联系人姓名"
                  v-model="formData.cma.requesterContact"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="联系电话"
                :rules="[
                  {
                    required: true,
                    message: '必须输入联系电话',
                    trigger: 'blur',
                  },
                ]"
                prop="cma.requesterContactTel"
              >
                <el-input
                  placeholder="联系电话"
                  v-model="formData.cma.requesterContactTel"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="CMA报告费用"
            ><span class="text-price"
              >{{ formData.cma.total_fee }}元</span
            ></el-form-item
          >
          <el-form-item label="当前账户余额"
            >{{ account.balance || 0.0 | amount }}元</el-form-item
          >
          <el-row>
            <el-col :span="12">
              <el-form-item label="余额抵扣">
                <span class="text-price">{{ formData.cma.balance }}元</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="使用余额支付">
                <el-switch
                  :disabled="account.balance <= 0.01"
                  v-model="formData.cma.useBalance"
                ></el-switch>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item>
            <div slot="label" class="wx-logo flex align-center">
              <span class="iconfont iconwxzhifu margin-right-m"></span>
              <span>微信支付</span>
            </div>
            <span class="text-price price">{{ formData.cma.payment }}</span
            >元
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" style="text-align: left">
        <el-row :gutter="10">
          <el-col :span="14">
            <div class="verify-code">
              <div v-show="showVerifyDlg">
                <span class="label-text">验证码</span>
                <el-input
                  ref="verifyCode"
                  class="input"
                  placeholder="请输入手机验证码"
                  v-model="verifyCode"
                ></el-input>
                <el-button
                  class="text-blue margin-left-xs"
                  size="small"
                  type="text"
                  :disabled="showCounter"
                  @click="getVerifyCode"
                >
                  {{ showCounter ? countDown + '秒' : '重新获取' }}
                </el-button>
              </div>
            </div>
          </el-col>
          <el-col :span="10">
            <el-button @click="() => (payCmaDlgVisible = false)"
              >取消</el-button
            >
            <el-button
              @click="pay4cma"
              type="primary"
              :disabled="showVerifyDlg && verifyCode.length == 0"
              >确认支付</el-button
            >
          </el-col>
        </el-row>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
    },
    tid: {
      type: String,
    },
    foodsName: {
      type: String,
    },
    visible: Boolean,
  },
  data: function () {
    return {
      showVerifyDlg: false,
      verifyCode: '',
      payCmaDlgVisible: false,
      price: 100,
      formData: {
        tid: this.tid,
        foodsName: this.foodsName,
        cma: {
          requesterName: '', // 委托单位名称
          requesterAddr: '', // 委托单位地址
          requesterContact: '', // 委托单位联系人
          requesterContactTel: '', // 委托单位联系电话

          useBalance: false, // 是否使用余额支付
          balance: 0, // 余额
          total_fee: 100, // 总费用
          payment: 100, // 实际支付
        },
      },
      account: { balance: 0 },
      showCounter: false,
      countDownTimer: null,
      countDown: 60,
      pay_id: null,
      showPayQrcode: false,
      prepayData: null,
      poller: null,
    };
  },
  watch: {
    value(v) {
      this.payCmaDlgVisible = v;
    },
    payCmaDlgVisible(v) {
      this.$emit('input', v);
    },
    tid(v) {
      this.formData.tid = v;
    },
    foodsName(v) {
      this.formData.foodsName = v;
    },
    'formData.cma.useBalance'(v) {
      this.refreshPayment();
      if (!v) {
        this.showVerifyDlg = false;
        this.stopCountDownTimer();
      }
    },
  },
  async mounted() {
    const result = await this.$api.getUnitPrice();
    if (result && result.data) {
      this.price = result.data.cma / 100 || 80;
      this.refreshPayment();
    }
    const { data } = await this.$api.account();
    this.account = data;
  },
  destroyed() {
    this.stopCountDownTimer();
  },
  methods: {
    refreshPayment() {
      if (this.formData.cma.useBalance) {
        this.formData.cma.total_fee = this.price;
        this.formData.cma.balance =
          this.price < this.account.balance ? this.price : this.account.balance;
        this.formData.cma.payment = this.price - this.formData.cma.balance;
      } else {
        this.formData.cma.total_fee = this.price;
        this.formData.cma.balance = 0;
        this.formData.cma.payment = this.price;
      }
    },
    closeDlg(done) {
      console.log('closeDlg');
      this.$emit('change', false);
      done();
    },

    async getVerifyCode() {
      if (this.countDownTimer) {
        console.log('倒计时中');
        return false;
      }
      this.countDown = 60;
      const { errcode } = await this.$api.mobile_post();
      if (errcode === 0) {
        this.$message.success('已向您的注册手机发送验证短信，请查收');
        this.startCountDownTimer();

        return true;
      }

      return false;
    },

    startCountDownTimer() {
      this.countDown = 60;
      this.showCounter = true;
      this.countDownTimer = setInterval(() => {
        if (this.countDown > 1) {
          this.countDown--;
        } else {
          clearInterval(this.countDownTimer);
          this.countDown = 60;
          this.countDownTimer = null;
          this.showCounter = false;
        }
      }, 1000);
    },

    stopCountDownTimer() {
      this.showCounter = false;
      if (this.countDownTimer) {
        clearInterval(this.countDownTimer);
        this.countDownTimer = null;
      }
    },

    pay4cma() {
      this.$refs['pay-form'].validate(async (valid) => {
        if (!valid) {
          this.$message.error('请填写完整信息');
          return false;
        }

        if (
          this.formData.cma.total_fee > 0 &&
          this.formData.cma.payment + this.formData.cma.balance ==
            this.formData.cma.total_fee
        ) {
          let params = {
            tids: [this.formData.tid],
            payment: this.formData.cma.total_fee,
            useBalance: this.formData.cma.useBalance,
            balance: this.formData.cma.balance,
            realPayment: this.formData.cma.payment,
            trade_type: 'NATIVE',
            cmaInfo: {
              required: true,
              requesterName: this.formData.cma.requesterName,
              requesterAddr: this.formData.cma.requesterAddr,
              requesterContact: this.formData.cma.requesterContact,
              requesterContactTel: this.formData.cma.requesterContactTel,
            },
          };

          // 仅使用余额支付
          if (this.formData.cma.payment == 0) {
            console.log('仅使用余额支付');
            if (this.verifyCode.length === 0) {
              if (await this.getVerifyCode()) {
                this.showVerifyDlg = true;
                return;
              }
            } else {
              await this.$api.mobile_put({ mobileCode: this.verifyCode });
              this.stopCountDownTimer();
              const payResult = await this.$api.payment_cma(params);
              if (payResult.errcode === 0) {
                this.$message.success('检验报告费用支付成功');
                this.$emit('pay-result', true);
              }
              this.payCmaDlgVisible = false;
            }
          } else {
            // 使用微信支付
            console.log('使用微信支付');
            const payResult = await this.$api.payment_cma(params);
            // test only
            // const payResult = {
            //   errcode: 0,
            //   data: {
            //     return_code: 'SUCCESS',
            //     return_msg: 'OK',
            //     result_code: 'SUCCESS',
            //     mch_id: '1617604067',
            //     appid: 'wxf686059e869fdeac',
            //     device_info: 'WEB',
            //     nonce_str: 'crozeH3KFH7xcvpe',
            //     sign: '8AB1BF2DCC673FE1F7B7E44772D9F73B',
            //     prepay_id: 'wx09100017666741dab1d949dd60ab860000',
            //     trade_type: 'NATIVE',
            //     code_url: 'weixin://wxpay/bizpayurl?pr=WbbOJQ7zz',
            //     out_trade_no: 'P20230709100033297505',
            //   },
            // };
            this.prepayData = payResult.data;
            if (
              payResult.errcode === 0 &&
              this.prepayData &&
              this.prepayData.out_trade_no &&
              this.prepayData.code_url
            ) {
              // this.$message.success('检验报告费用在线支付成功');
              this.pay_id = this.prepayData.out_trade_no;
              this.showPayQrcode = true;
            } else {
              this.$message.error('检验报告费用在线支付失败');
              this.payCmaDlgVisible = false;
            }
          }
        } else {
          this.$message.error('支付金额错误');
        }
      });
    },

    // 生成二维码
    showQrcode() {
      document.getElementById('qrcode').innerHTML = '';
      this.$QRCodeSC(this.prepayData.code_url);
      this.poll();
    },

    cancelWxPay(done) {
      document.getElementById('qrcode').innerHTML = '';
      this.pay_id = null;
      this.prepayData = null;
      if (this.poller) clearTimeout(this.poller);
      done();
    },

    // 轮询支付状态
    poll() {
      const that = this;
      that.$api
        .get_pay(that.pay_id)
        .then((res) => {
          if (res.data.status === 2) {
            that.$message.success('检验报告费用支付成功');
            this.showPayQrcode = false;
            this.payCmaDlgVisible = false;
            clearTimeout(that.poller);
            that.$emit('pay-result', true);
          } else {
            that.poller = setTimeout(() => {
              that.poll();
            }, 1000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};

</script>

<style lang="scss" scoped>
.price {
  font-size: 16px;
  font-weight: bold;
  color: rgb(44, 163, 57);
}

.verify-code {
  min-height: 36px;
  .label-text {
    width: 80px;
    margin-right: 10px;
  }

  .input {
    width: 150px;
  }
}

.wx-logo {
  // background: #ffffff;
  // padding: 6px 12px;
  // font-size: 14px;
  // border: 1px solid rgb(213, 213, 213);
  vertical-align: middle;
  line-height: 32px;
  .iconfont {
    color: rgb(44, 163, 57);
    // font-size: 21px;
    vertical-align: middle;
  }
}
.pop_bg {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #000000;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  z-index: 9005;
}
.pop_code {
  // position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  text-align: center;
  z-index: 9006;

  .pop_box {
    display: inline-block;
    width: 380px;
    padding-bottom: 10px;
    vertical-align: middle;
    background-color: #ffffff;
    border-radius: 4px;
    border: 1px solid #ebeef5;
    font-size: 18px;
    -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    text-align: left;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    padding-bottom: 30px;
    .title {
      position: relative;
      padding: 15px;
      padding-bottom: 10px;
      .name {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding-left: 0;
        margin-bottom: 0;
        font-size: 18px;
        line-height: 1;
        color: #303133;
        line-height: 30px;
      }
      .title_btn {
        position: absolute;
        top: 5px;
        right: 5px;
        padding: 0;
        border: none;
        outline: none;
        background: transparent;
        font-size: 16px;
        padding: 15px;
        cursor: pointer;
      }
      .iconfont {
        font-size: 30px;
        color: #09ba07;
        line-height: 30px;
        display: inline-block;
        vertical-align: top;
        padding-right: 15px;
      }
    }
    .code_img {
      padding-left: 27px;
      padding-right: 27px;
      text-align: center;
      position: relative;
      padding: 10px 15px;
      font-size: 0;
      line-height: 0;
    }
    .code_text {
      text-align: center;
      font-size: 16px;
      .money {
        font-weight: bold;
      }
    }
    .wx_pay {
      font-size: 0;
      line-height: 0;
      text-align: center;
      width: 200px;
      margin: auto;
      img {
        width: 100%;
        text-align: center;
      }
    }
  }
  .pop_box_wrap {
    display: inline-block;
    margin: auto;
  }
}
.pop_code:after {
  content: '';
  display: inline-block;
  height: 100%;
  width: 0;
  vertical-align: middle;
}
</style>
