<template>
  <div>
    <div class="flex margin-tb-sm">
      <div class="margin-right-xl">
        <div class="margin-bottom-sm">
          <el-radio-group v-model="currentMode">
            <el-radio-button :label="false">经典模式</el-radio-button>
            <el-radio-button :label="true">批量模式</el-radio-button>
          </el-radio-group>
        </div>
        <router-link to="submit_tag">
          <el-button
            type="primary"
            @click="MtaH5.clickStat('food', { add: 'true' })"
            icon="el-icon-plus"
            >提交新的食品标签</el-button
          >
        </router-link>
      </div>
      <div class="flex-sub">
        <BatchSearch @change="searchChange" />
      </div>
    </div>
    <div>
      <div v-if="currentMode">
        <BatchMode :searchVal="batchForm" />
      </div>
      <div v-else>
        <TableArr :searchVal="batchForm" />
      </div>
    </div>
  </div>
</template>

<script>
import { set_date } from "@/assets/js/validate";
import TableArr from "./components/table_arr";
import BatchSearch from "./components/batchMode/batchSearch";
import BatchMode from "./components/batchMode";
import _ from "lodash";
export default {
  components: {
    TableArr,
    BatchMode,
    BatchSearch,
  },
  data() {
    return {
      batchForm: {},
      id_list: "",
      currentMode: this.isBatch,
    };
  },
  watch: {
    currentMode(v) {
      this.$store.commit("set_mode", v);
    },
  },
  computed: {
    isBatch() {
      return this.$store.getters.isBatch;
    },
  },
  mounted() {
    this.currentMode = this.isBatch;
  },
  methods: {
    searchChange(v) {
      this.batchForm = { ...v };
    },
    set_time(e) {
      this.info.time = set_date(e);
    },
  },
};
</script> 