import ExeclJS from "exceljs";
import * as FileSaver from "file-saver";
import axios from "axios";
import moment from "moment";

const columns = [
  {
    header: "序号",
    key: "no",
    width: 5,
    style: {
      alignment: { vertical: "middle", horizontal: "left" },
    },
  },
  {
    header: "订单号",
    key: "tid",
    width: 23,
    style: {
      alignment: { vertical: "middle", horizontal: "left" },
    },
  },
  {
    header: "食品名称/产品编号",
    key: "foodName",
    width: 20,
    style: {
      alignment: { vertical: "middle", horizontal: "left" },
    },
  },
  {
    header: "审核结果",
    key: "result",
    width: 9,
    style: {
      alignment: { vertical: "middle", horizontal: "left" },
    },
  },
  {
    header: "不合格/瑕疵数量",
    key: "ngCount",
    width: 18,
    style: {
      alignment: { vertical: "middle", horizontal: "left" },
    },
  },
  {
    header: "类型",
    key: "ngType",
    width: 9,
    style: {
      alignment: { vertical: "middle", horizontal: "left" },
    },
  },
  {
    header: "不合格/瑕疵分类",
    key: "ngItem",
    width: 20,
    style: {
      alignment: { vertical: "middle", horizontal: "left" },
    },
  },
  {
    header: "判定依据",
    key: "ngRegulation",
    width: 30,
    style: {
      alignment: { vertical: "middle", horizontal: "left" },
    },
  },
  {
    header: "不合格/瑕疵说明",
    key: "ngRequirement",
    width: 50,
    style: {
      alignment: { vertical: "middle", horizontal: "left" },
    },
  },
  {
    header: "截图",
    key: "shortcut",
    width: 50,
    style: {
      alignment: { vertical: "middle", horizontal: "left" },
    },
  },
  {
    header: "初审",
    key: "checker",
    width: 6,
    style: {
      alignment: { vertical: "middle", horizontal: "left" },
    },
  },
  {
    header: "复核",
    key: "reviewer",
    width: 6,
    style: {
      alignment: { vertical: "middle", horizontal: "left" },
    },
  },
  {
    header: "完成时间",
    key: "reportDate",
    width: 20,
    style: {
      alignment: { vertical: "middle", horizontal: "left" },
    },
  },
];

async function downloadImgToBuff(url) {
  let { data } = await axios({ method: "get", url, responseType: "arraybuffer" });
  return data;
}

class ExcelHelper {
  constructor(data) {
    this.data = data;
    this.workbook = new ExeclJS.Workbook();
    this.workbook.creator = "士海食标";
    this.workbook.created = new Date();
    this.sheet = this.workbook.addWorksheet("审核结果", { views: [{ showGridLines: false }] });
    this.sheet.columns = columns;
    this.pics = [];
  }

  async _getRows() {
    let data = this.data;
    let no = 1;
    // 记录图片编号
    let picIndex = 0;

    let rows = [];
    console.log(data);
    for (let item of data) {
      let subNo = 0; //行内的错误计数
      try {
        let row = {
          no: no,
          tid: item.tid,
          foodName: item.foodName,
          ngCount: item.ngCount + item.defectCount,
          checker: item.checker,
          reviewer: item.reviewer,
          reportDate: item.reportDate,
          result: item.ngCount > 0 ? "不合格" : item.defectCount > 0 ? "存在瑕疵" : "合格",
        };

        if (item.ngCount > 0 && item.ngItems.length > 0) {
          let list = [].concat(item.ngItems, item.defectItems);
          let ngCount = item.ngItems.length;

          for (let one of list) {
            // 不是第一行的情况下，其他字段均为空
            if (subNo > 0) {
              row = {
                no: null,
                tid: null,
                foodName: null,
                ngCount: null,
                defectCount: null,
                checker: null,
                reviewer: null,
                reportDate: null,
                result: null,
              };
            }

            // 先呈现不合格项，再呈现瑕疵项
            row.ngType = subNo < ngCount ? "不合格" : "瑕疵";
            row.ngItem = one.itemName;
            row.ngRegulation = one.regulation;
            row.ngRequirement = one.requirement;
            // 下载截图
            row.shortcut = null;
            if (one.checkImage && one.checkImage.length > 0) {
              let imgInfo = one.checkImage[0];
              if (imgInfo.mimetype.startsWith("image/")) {
                let type = imgInfo.mimetype.slice(6).trim();
                let url = imgInfo.url;
                try {
                  let buff = await downloadImgToBuff(url);
                  let imgId = this.workbook.addImage({
                    buffer: buff,
                    extension: type,
                  });
                  this.pics[picIndex] = imgId;
                  row.shortcut = picIndex; // 将图片id写入表格中
                  picIndex++;
                } catch (ex) {
                  console.error(ex);
                }
              }
            }
            rows.push(row);
            subNo++;
          }
        } else {
          rows.push(row);
        }
        no++;
      } catch (ex) {
        console.error("数据错误", item);
        console.error(ex);
        throw ex;
      }
    }
    return rows;
  }

  async generate() {
    let rows = await this._getRows();
    for (let row of rows) {
      let rowObj = this.sheet.addRow(row);
      // 调整图片行的高度, 需要在填充图片前完成
      if (rowObj.getCell("shortcut").value !== null) {
        rowObj.height = 80;
      }
    }
    this._fillImgToCell();
    this._adjustStyle();
  }

  output() {
    this.workbook.xlsx
      .writeBuffer()
      .then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        let fileName = `标签审核结果汇总表(${moment().format("YYYY年M月D日H时m分")})`;
        FileSaver.saveAs(blob, fileName);
      })
      .catch((err) => {
        console.error("导出错误", err);
      });
  }

  _fillImgToCell() {
    let col = this.sheet.getColumn("shortcut");
    col.eachCell((cell, rowNumber) => {
      if (rowNumber > 1) {
        console.log("cell", cell);
        if (cell.value !== null) {
          let imgIndex = parseInt(cell.value);
          let imgId = this.pics[imgIndex];
          console.log("imgId", imgId);
          // 调整图片行的高度
          //   let picRow = this.sheet.getRow(cell.row);
          //   picRow.height = 50;
          this.sheet.addImage(imgId, {
            tl: { col: cell.col - 1 + 0.2, row: cell.row - 1 + 0.1 },
            br: { col: cell.col, row: cell.row },
          });
        }
      }
    });
  }

  _adjustStyle() {
    this.sheet.eachRow((row, rowNumber) => {
      row.eachCell((cell, colNumber) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
    });

    //表头加粗
    let firstRow = this.sheet.getRow(1);
    firstRow.font = { bold: true, size: 12 };

    let colKeys = ["no", "tid", "foodName", "result", "ngCount", "checker", "reviewer", "reportDate"];
    for (let key of colKeys) {
      let colObj = this.sheet.getColumn(key);
      let lastRowNo = 0;
      let maxRowNo = 0;
      let toMerge = [];
      colObj.eachCell((cell, rowNumber) => {
        if (rowNumber > 1) {
          if (cell.value !== null) {
            if (lastRowNo > 1 && lastRowNo < rowNumber) {
              toMerge.push({ startRow: lastRowNo, startCol: cell.col, stopRow: rowNumber - 1, stopCol: cell.col });
            }
            lastRowNo = rowNumber;
          }
        }
        maxRowNo = Math.max(maxRowNo, rowNumber);
      });

      toMerge.push({ startRow: lastRowNo, startCol: colObj.number, stopRow: maxRowNo, stopCol: colObj.number });

      for (let one of toMerge) {
        this.sheet.mergeCells(one.startRow, one.startCol, one.stopRow, one.stopCol);
      }
    }
  }
}

async function generateExcelFile(data) {
  let excelHelper = new ExcelHelper(data);
  await excelHelper.generate();
  excelHelper.output();
}

export default generateExcelFile;
