<template>
  <div>
    <el-table
      :expand-row-keys="expandRowKeys"
      @expand-change="expandChange"
      :data="tableData"
      row-key="_id"
    >
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-table
           
            v-loading="!props.row.expand"
            :data="props.row.expand || []"
            row-key="_id"
            row-class-name="batch-gray"
          >
            <el-table-column
              v-for="(item, index) in expandColumn"
              :key="index"
              :label="item.title"
              :prop="item.dataIndex"
              :fixed="item.fixed"
            >
              <template slot-scope="scope">
                <div v-if="item.fixed">
                  <ActionBtn
                    :item="scope.row"
                    @change="() => expandChange(scope.row, true)"
                    @submit="resetBtn(scope.row)"
                  />
                </div>

                <router-link
                  v-else-if="item.dataIndex === 'tid'"
                  :to="'./food_details?id=' + scope.row.tid"
                >
                  <a class="down text-blue">{{ scope.row.tid }}</a>
                </router-link>

                <div v-else>
                  {{
                    item.renderText
                      ? item.renderText(scope.row)
                      : scope.row[item.dataIndex]
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, index) in column"
        :key="index"
        :label="item.title"
        :prop="item.dataIndex"
      >
        <template slot-scope="scope">
          <a
            v-if="
              item.dataIndex === 'finishNum' &&
              scope.row.finishNum === scope.row.labelNum
            "
            class="text-blue down"
            @click="downFile(scope.row)"
          >
            [下载]
          </a>
          {{
            item.renderText
              ? item.renderText(scope.row)
              : scope.row[item.dataIndex]
          }}
        </template>
      </el-table-column>
    </el-table>
    <div class="margin-top-sm flex">
      <span class="flex-sub"></span>
      <el-pagination
        @size-change="(size) => (params.pageSize = size)"
        @current-change="(v) => (params.pageIndex = v)"
        :current-page.sync="params.pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="params.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <ResetTag
      :item="curItem"
      v-model="showInput"
      @submit="expandChange(curItem, true)"
    />
  </div>
</template>
<script>
import moment from "moment";
import accounting from "accounting";
import { downOrderFile, getBatchOrderList, getOrderBatchInfo } from "./service";
import ActionBtn from "../actionBtn.vue";
import ResetTag from "../resetTag";
import _ from "lodash";
import Vue from "vue";
export default {
  components: {
    ActionBtn,
    ResetTag,
  },
  props: {
    searchVal: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      curItem: {},
      showInput: false,
      column: [
        { title: "批次号", dataIndex: "_id" },
        {
          title: "数量",
          dataIndex: "labelNum",
          renderText({ labelNum = 0 }) {
            return labelNum + "份";
          },
        },
        { title: "名称", dataIndex: "someFood" },
        {
          title: "提交日期",
          dataIndex: "submitTime",
          renderText({ submitTime }) {
            return submitTime ? moment(submitTime).format("YYYY-MM-DD") : "-";
          },
        },
        {
          title: "已审核",
          dataIndex: "finishNum",
          renderText({ finishNum = 0 }) {
            return `${finishNum}份`;
          },
        },
        {
          title: "已支付",
          dataIndex: "totalPaid",
          renderText({ totalPaid = 0 }) {
            return accounting.formatMoney(totalPaid, "￥", 2);
          },
        },
        {
          title: "总金额",
          dataIndex: "totalPayment",
          renderText({ totalPayment = 0 }) {
            return accounting.formatMoney(totalPayment, "￥", 2);
          },
        },
        {
          title: "状态",
          dataIndex: "payStatus",
          renderText({ payStatus }) {
            return (
              {
                0: "未付款",
                1: "部分付款",
                2: "已支付",
              }[payStatus] || "-"
            );
          },
        },
        {
          title: "支付时间",
          dataIndex: "payTime",
          renderText({ payTime }) {
            return payTime ? moment(payTime).format("YYYY-MM-DD") : "-";
          },
        },
      ],
      expandColumn: [
        {
          title: "订单号",
          dataIndex: "tid",
        },
        {
          title: "食品名称",
          dataIndex: "foodName",
        },
        {
          title: "审核费用",
          dataIndex: "payment",
          renderText({ payment = 0 }) {
            return accounting.formatMoney(payment, "￥", 2);
          },
        },
        {
          title: "下单时间",
          dataIndex: "createAt",
          renderText({ createAt }) {
            return createAt ? moment(createAt).format("YYYY-MM-DD") : "-";
          },
        },
        {
          title: "订单状态",
          dataIndex: "showStatus",
          renderText({ showStatus }) {
            return Vue.filter('orderStatus')(showStatus);
          },
        },
        {
          title: "付款状态",
          dataIndex: "pay_status",
          renderText({ pay_status }) {
            return pay_status ? "已付款" : "未付款";
          },
        },
        {
          title: "付款时间",
          dataIndex: "pay_time",
          renderText({ pay_time }) {
            return pay_time ? moment(pay_time).format("YYYY-MM-DD") : "-";
          },
        },
        {
          title: "操作",
          width: 120,
          fixed: "right",
        },
      ],
      tableData: [],
      params: {
        pageSize: 10,
        pageIndex: 1,
      },
      total: 0,
      expandRowKeys: [],
    };
  },
  watch: {
    showInput(v) {
      if (!v) this.curItem = {};
    },
    searchVal: {
      handler() {
        this.params.pageIndex = 1;
        this.getList();
      },
      deep: true,
    },
    params: {
      handler() {
        this.getList();
      },
      deep: true,
    },
  },
  methods: {
    async getList() {
      this.expandRowKeys = [];
      const { data } = await getBatchOrderList({
        ...this.searchVal,
        ...this.params,
      });
      this.tableData = data.list || [];
      this.total = data.totalCount;
    },
    async expandChange(row, status) {
      if (_.isArray(status)) {
        this.expandRowKeys = status.map((i) => i._id);
      }
      if (!row.expand || status === true) {
        const batchNo = status === true ? row.batchNo : row._id;
        const { data } = await getOrderBatchInfo(batchNo);
        this.tableData = this.tableData.map((i) => {
          if (i._id === batchNo) {
            return {
              ...i,
              expand: data,
            };
          }
          return i;
        });
      }
    },
    resetBtn(item) {
      this.curItem = item;
      this.showInput = true;
    },
    async downFile(item) {
      window.open(`/api/packreports/${item._id}`, "_blank");
    },
  },
};
</script> 
<style lang="scss"  >
.down {
  cursor: pointer;
}
.batch-gray {
  background-color: #f1f1f1 !important;
}
</style>