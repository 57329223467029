<template>
  <div class="table_list">
    <el-button
      style="font-size: 14px; padding: 10px"
      @click="goBuy"
      :disabled="
        this.batchPayList.length == 0 ||
        this.batchPayList.length != this.selList.length
      "
      >批量付款
    </el-button>
    <el-button
      type="info"
      style="font-size: 14px; padding: 10px"
      @click="exportExcel"
      :disabled="
        this.batchOutList.length == 0 ||
        this.batchOutList.length != this.selList.length
      "
      >批量导出审核结果
    </el-button>
    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        width="48"
        :selectable="(row) => row.type === '待付款' || row.type === '审核完成'"
      />
      <el-table-column label="全选" width="48">
        <template slot-scope="scope">
          <div class="iconfont" :class="scope.row.icon"></div>
        </template>
      </el-table-column>
      <el-table-column label="订单编号" width="190">
        <template slot-scope="scope">
          <router-link :to="'./food_details?id=' + scope.row.tid">
            <span class="text-blue">{{ scope.row.tid }}</span>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="80">
        <template slot-scope="scope">
          <ActionBtn
            @change="(v) => table_arr()"
            @submit="retrial_btn(scope.row)"
            :item="scope.row"
          />
        </template>
      </el-table-column>
      <el-table-column prop="name" label="食品名称"></el-table-column>
      <el-table-column prop="specs" label="规格"></el-table-column>
      <el-table-column
        v-if="!$store.getters.isCooperationCustomer"
        prop="cma.required"
        label="检验报告"
      >
        <template slot-scope="scope">
          <el-tag
            v-if="scope.row.cmaStatus"
            :type="scope.row.cmaStatusType"
            size="small"
            >{{ scope.row.cmaStatus }}</el-tag
          >
          <el-button
            v-else-if="
              scope.row.pay_status == 1 &&
              scope.row.showStatus !== 7 &&
              !scope.row.cma.required
            "
            type="primary"
            size="mini"
            @click="showCmaPayDlg(scope.row)"
            >申请出具</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="date"
        label="提交时间"
        width="170"
      ></el-table-column>
      <el-table-column
        prop="type"
        label="订单状态"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="money"
        label="审核费用"
        width="100"
      ></el-table-column>
      <el-table-column prop="state" label="付款状态" width="120">
        <template slot-scope="{ row }">
          <div v-if="row.couponCodes.length" class="text-xs">
            优惠券
            <span class="text-red text-bold"
              >-{{ row.couponDiscountInOrder | currency }}
            </span>
          </div>
          <div>
            <el-link
              :type="row.pay_status ? 'success' : 'warning'"
              :underline="false"
            >
              {{ row.pay_status ? '已付款' : '未付款' }}
            </el-link>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="time"
        label="付款时间"
        width="150"
      ></el-table-column>
    </el-table>
    <div class="paging_arr clr">
      <div class="btn_right fr">
        <el-pagination
          @size-change="handleSizeChange"
          :current-page.sync="info.pageIndex"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          layout="sizes, prev, pager, next"
          :total="table_length"
        ></el-pagination>
      </div>
      <div class="hint_left fr">共找到 {{ table_length }} 条内容</div>
    </div>
    <AppendPayCmaReport
      :tid="curItem.tid"
      :foodsName="curItem.name"
      v-model="payCmaDlgVisible"
      @pay-result="handlePayResult"
    />
    <ResetTag :data="curItem" v-model="showInput" @submit="table_arr" />
  </div>
</template>
<script>
import { moment_set } from '@/assets/js/validate';
import ActionBtn from './actionBtn.vue';
import ResetTag from './resetTag';
import AppendPayCmaReport from './appendPayCmaReport.vue';
import generateExcelFile from '../../../utils/exportExcel';
import Vue from 'vue';
export default {
  components: { ResetTag, ActionBtn, AppendPayCmaReport },
  props: {
    searchVal: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      curItem: {},
      showInput: false,
      tableData: [],
      multipleSelection: [],
      info: {
        pageIndex: 1,
        pageSize: 10,
        keyword: '',
        status: 0,
        start: '',
        end: '',
      },
      table_length: 0,
      pop: false,
      desc: '',
      data: '',
      buyList: '',
      batchPayList: [],
      batchOutList: [],
      selList: [],
      payCmaDlgVisible: false,
    };
  },
  watch: {
    showInput(v) {
      if (!v) this.curItem = {};
    },
    searchVal: {
      handler(content) {
        this.info.pageIndex = 1;
        this.info.status = content.status || 0;
        this.info.start = moment_set(content.start);
        this.info.end = moment_set(content.end);
        this.info.keyword = content.keyword;
        this.table_arr();
      },
      deep: true,
    },
  },
  methods: {
    showCmaPayDlg(item) {
      this.curItem = item;
      this.payCmaDlgVisible = true;
    },

    handlePayResult(reulst) {
      if (reulst) {
        this.table_arr();
      }
    },

    retrial_btn(item) {
      this.curItem = item;
      this.showInput = true;
    },

    handleSizeChange(e) {
      this.info.pageSize = e;
      this.table_arr();
    },
    handleCurrentChange(e) {
      this.info.pageIndex = e;
      this.table_arr();
    },
    handleSelectionChange(list = []) {
      this.selList = list;
      this.batchPayList = list.filter((item) => item.status == 1); //所勾选的待付款订单列表
      this.batchOutList = list.filter((item) => item.status == 9); //所勾选的审核完成订单列表
      if (this.batchPayList.length > 0) {
        this.buyList = list.map((i) => i.tid).join(',');
      } else {
        this.buyList = '';
      }
    },
    goBuy() {
      if (!this.buyList) return this.$message.warning('请先勾选订单');
      this.$router.push({ path: `submit_tag?state=3&id=${this.buyList}` });
    },
    async exportExcel() {
      if (!this.batchOutList || this.batchOutList.length == 0)
        return this.$message.warning('请选择已经审核完成的订单');
      const tids = this.batchOutList.map((order) => order.tid);
      const { data } = await this.$api.getExcelData({ tradeIds: tids });
      generateExcelFile(data);
    },
    async table_arr() {
      const tableData = [];
      const { data } = await this.$api.orders(this.info);
      data.list.forEach((e) => {
        let cmaStatus = '';
        let cmaStatusType = 'info';
        if (e.cma && e.cma.required && e.pay_status == 1) {
          // 需要出具报告，但未进入CMA流程
          if (e.showStatus < 50) {
            cmaStatus = '待出具';
            cmaStatusType = 'warning';
          } else if (e.showStatus == 54) {
            cmaStatus = '已签发';
            cmaStatusType = 'success';
          } else {
            cmaStatus = '处理中';
          }
        }
        tableData.push({
          ...e,
          date: e.createAt,
          name: e.foodName,
          icon: e.pay_status === 0 ? 'iconweifukuan' : 'iconyifukuan',
          id: e._id,
          specs: e.orderDetail && e.orderDetail.specs,
          date: moment_set(e.createAt),
          money: e.payment,
          type: Vue.filter('orderStatus')(e.showStatus),
          state: e.pay_status === 0 ? '未付款' : '已付款',
          time: moment_set(e.pay_time),
          report: e.report || '',
          cmaStatus,
          cmaStatusType,
        });
      });
      this.tableData = tableData;
      this.table_length = data.totalCount;
    },
  },
};
</script>

<style lang="scss">
.table_list {
  .paging_arr {
    margin-top: 40px;
    .hint_left {
      font-size: 12px;
      line-height: 33px;
    }
  }
  .set_btn {
    .el-button--text {
      padding: 0;
    }
  }
  .el-table thead {
    color: #000000;
  }
  .el-table__body {
    border-collapse: separate;
    border-spacing: 0 4px;
    background: #eee;
  }
  .el-table th {
    background: #eee;
  }
  .el-table__row {
    td:nth-child(2) {
      background: rgb(242, 200, 17);
      text-align: center;
      .iconfont {
        font-size: 23px;
      }
    }
  }

  .el-table .hover-row {
    background: rgba(242, 200, 17, 0.5);
  }
  .el-table .el-table__row:hover {
    background: rgba(242, 200, 17, 0.7);
  }
  // 鼠标悬浮
  .el-table--enable-row-hover .el-table__body tr:hover {
    background: rgb(242, 200, 17);
    border: 1px solid #313463;
    box-shadow: 0 0 1px 1px rgb(242, 200, 17),
      inset 0 0 10px 5px rgba(242, 200, 17, 0.2);
  }
  .el-pager li.active {
    color: #000;
    cursor: default;
    background: #f2c811;
  }
}
</style>
