<template>
  <el-form
    label-position="left"
    :inline="true"
    :model="form"
    ref="form"
    label-width="auto"
  >
    <el-form-item v-if="!isBatch" label="状态">
      <el-select
        v-model="form.status"
        placeholder="订单状态"
        style="width: 120px"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item :label="isBatch ? '批次' : '关键字'">
      <el-input
        :placeholder="isBatch ? '请输入批次号查询' : '食品名称、订单号 ... '"
        v-model="form.keyword"
        style="width: 100%"
      />
    </el-form-item>
    <el-form-item label="时间：">
      <el-row>
        <el-col :span="11">
          <el-date-picker
            type="datetime"
            placeholder="选择日期"
            v-model="form.start"
            style="width: 100%"
          />
        </el-col>
        <el-col class="text-center" :span="2">-</el-col>
        <el-col :span="11">
          <el-date-picker
            type="datetime"
            placeholder="结束日期"
            v-model="form.end"
            style="width: 100%"
          />
        </el-col>
      </el-row>
      <div class="flex">
        <span class="flex-sub"></span>
        <ul class="flex">
          <li
            class="margin-left"
            v-for="(item, index) in ['今天', '昨天', '最近一周', '最近一月']"
            :key="index"
          >
            <el-button size="small" type="text" @click="setTime(index + 1)">{{
              item
            }}</el-button>
          </li>
        </ul>
      </div>
    </el-form-item>

    <el-form-item label=" ">
      <el-button type="primary" plain @click="$emit('change', form)"
        >立即查询</el-button
      >
    </el-form-item>
  </el-form>
</template>
<script>
import { set_date } from '@/assets/js/validate';
export default {
  data() {
    return {
      options: [
        {
          value: 0,
          label: '全部',
        },
        {
          value: 1,
          label: '待付款',
        },
        {
          value: 2,
          label: '待审核',
        },
        {
          value: 3,
          label: '审核中',
        },
        {
          value: 4,
          label: '审核完成',
        },
        { value: 5, label: '已返款' },
        {
          value: 6,
          label: '已关闭',
        },
        {
          value: 20,
          label: '待修改',
        },
        {
          value: 21,
          label: '撤销重审',
        },
        // add status for cma report
        {
          value: 50,
          label: '报告编制中',
        },
        {
          value: 54,
          label: '报告已签发',
        },
      ],
      form: {
        start: undefined,
        end: undefined,
        status: 0,
      },
    };
  },
  watch: {
    isBatch() {
      this.form = { start: undefined, end: undefined };
      this.$emit('change', this.form);
    },
  },
  computed: {
    isBatch() {
      return this.$store.getters.isBatch;
    },
  },
  mounted() {
    if (!this.isBatch) this.form.status = parseInt(this.$route.query.id) || 0;
    setTimeout(() => this.$emit('change', this.form));
  },
  methods: {
    setTime(data) {
      this.form.start = set_date(data)[0];
      this.form.end = set_date(data)[1];
    },
  },
};
</script>
